<template>
    <footer>
        <hr />
        <div class="footer-container side-margins">
            <IconLogoProjection />
            <div class="footer-content d-flex">
                <ul class="pt-2 pr-5">
                    <li>555 SE Martin Luther King Jr Blvd</li>
                    <li>Suite 520</li>
                    <li>Portland, Oregon 97214</li>
                </ul>
                <ul class="pt-2 pr-5">
                    <li><a href="mailto:info@upswell.studio">info@upswell.studio</a></li>
                    <li>+1 503 660 3261</li>
                </ul>
                <ul class="pt-2 pr-5">
                    <li><a href="https://www.instagram.com/helloupswell/?hl=en" target="_blank">Instagram</a></li>
                    <li><a href="https://vimeo.com/upswell" target="_blank">Vimeo</a></li>
                    <li><a href="https://www.linkedin.com/company/upswell/" target="_blank">LinkedIn</a></li>
                </ul>
                <ul class="pt-2 pr-5">
                    <li><router-link to="/p/privacy-policy">Privacy Policy</router-link></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
import IconLogoProjection from './icons/IconLogoProjection.vue'

export default {
    components: {
        IconLogoProjection,
    },
}
</script>
<style lang='scss' scoped>
    .footer-container {
        padding: 60px 0;
    }
    .footer-content {
        li {
            padding: 3px 0;
        }
        a {
            text-decoration: none;
            color: $upswell-light-blue;
        }
        a:hover {
            color: $upswell-white;
            text-decoration: none;
        }
    }
    @media (max-width: 768px) {
        .footer-content {
            flex-direction: column;
        }
    }
</style>